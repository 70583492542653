import React, {useEffect, useMemo, useCallback, useReducer, useState} from 'react';
//
import {ActionMapType, DataStateType} from '../types';
import {DataContext} from './data-context';
import {Form} from "../../../models/Form";
import i18next from "i18next";


// ----------------------------------------------------------------------
enum Types {
    // INITIAL
    INITIAL = 'INITIAL',
    // ADD
    ADD_INTEGRATION = 'ADD_INTEGRATION',
    ADD_INTEGRATION_OPTIONS = 'ADD_INTEGRATION_OPTIONS',
    // UPDATE
    UPDATE_COLOR = 'UPDATE_COLOR',
    UPDATE_LOGO = 'UPDATE_LOGO',
    UPDATE_FAV_ICON = 'UPDATE_FAV_ICON',
    UPDATE_PAGE_TITLE = 'UPDATE_PAGE_TITLE',
    UPDATE_SURVEY_ID = 'UPDATE_SURVEY_ID',
    UPDATE_PUBLIC_KEY = 'UPDATE_PUBLIC_KEY',
    UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
    UPDATE_LOADING = 'UPDATE_LOADING'
}

type Payload = {
    // INITIAL
    [Types.INITIAL]: DataStateType;
    // ADD
    [Types.ADD_INTEGRATION]: {
        integration: Form,
    };
    // UPDATE
    [Types.UPDATE_COLOR]: {
        color: string,
    };
    [Types.UPDATE_LOGO]: {
        logo: string,
    };
    [Types.UPDATE_FAV_ICON]: {
        favIcon: string,
    };
    [Types.UPDATE_PAGE_TITLE]: {
        pageTitle: string,
    };
    [Types.UPDATE_SURVEY_ID]: {
        surveyId: string,
    };
    [Types.UPDATE_PUBLIC_KEY]: {
        publicKey: string,
    };
    [Types.UPDATE_LANGUAGE]: {
        language: string,
    };
    [Types.UPDATE_LOADING]: {
        loading: boolean,
    }
};

type Action = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: DataStateType = {
    integration: null,
    color: '',
    logo: '',
    favIcon: '',
    pageTitle: '',
    surveyId: '',
    publicKey: '',
    language: '',
    direction: 'ltr',
    loading: true
};

const reducer = (state: DataStateType, action: Action) => {
    // INITIAL
    if (action.type === Types.INITIAL) {
        return initialState;
    }

    // ADD
    if (action.type === Types.ADD_INTEGRATION) {
        return {
            ...state,
            integration: action.payload.integration,
        };
    }

    // UPDATE
    if (action.type === Types.UPDATE_COLOR) {
        return {
            ...state,
            color: action.payload.color,
        };
    }

    if (action.type === Types.UPDATE_LOGO) {
        return {
            ...state,
            logo: action.payload.logo,
        };
    }

    if (action.type === Types.UPDATE_FAV_ICON) {
        return {
            ...state,
            favIcon: action.payload.favIcon,
        };
    }

    if (action.type === Types.UPDATE_PAGE_TITLE) {
        return {
            ...state,
            pageTitle: action.payload.pageTitle,
        };
    }

    if (action.type === Types.UPDATE_SURVEY_ID) {
        return {
            ...state,
            surveyId: action.payload.surveyId,
        };
    }

    if (action.type === Types.UPDATE_PUBLIC_KEY) {
        return {
            ...state,
            publicKey: action.payload.publicKey,
        };
    }

    if (action.type === Types.UPDATE_LANGUAGE) {
        return {
            ...state,
            language: action.payload.language,
        };
    }

    if (action.type === Types.UPDATE_LOADING) {
        return {
            ...state,
            loading: action.payload.loading
        }
    }

    return state;
};
// ----------------------------------------------------------------------

type DataProviderProps = {
    children: React.ReactNode;
};

export function DataProvider({children}: DataProviderProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [direction, setDirection] = useState<'ltr' | 'rtl'>('ltr');

    const initialize = useCallback(() => {
        dispatch({
            type: Types.INITIAL,
            payload: initialState,
        });
    }, []);

    useEffect(() => {
        initialize();
    }, [initialize]);

    // Add
    const addIntegration = useCallback((integration: Form) => {
        dispatch({
            type: Types.ADD_INTEGRATION,
            payload: {
                integration,
            },
        });
    }, []);

    // Update
    const updateColor = useCallback((color: string = '#E0F2FE') => {
        // Update class .App background-color with response.style.backgroundColor
        document.querySelector('.App')?.setAttribute('style', `background-color: ${color} !important;`);

        dispatch({
            type: Types.UPDATE_COLOR,
            payload: {
                color,
            },
        });
    }, []);

    const updateLogo = useCallback((logo: string) => {
        dispatch({
            type: Types.UPDATE_LOGO,
            payload: {
                logo,
            },
        });
    }, []);

    const updateFavIcon = useCallback((favIcon: string) => {
        dispatch({
            type: Types.UPDATE_FAV_ICON,
            payload: {
                favIcon,
            },
        });
    }, []);

    const updatePageTitle = useCallback((pageTitle: string) => {
        dispatch({
            type: Types.UPDATE_PAGE_TITLE,
            payload: {
                pageTitle,
            },
        });
    }, []);

    const updateSurveyId = useCallback((surveyId: string) => {
        dispatch({
            type: Types.UPDATE_SURVEY_ID,
            payload: {
                surveyId,
            },
        });
    }, []);

    const updatePublicKey = useCallback((publicKey: string) => {
        dispatch({
            type: Types.UPDATE_PUBLIC_KEY,
            payload: {
                publicKey,
            },
        });
    }, []);


    const updateLanguage = useCallback((language: string) => {
        localStorage.setItem('lang', language);
        // Set lang file
        i18next.changeLanguage(language);
        // Set the <html lang> attribute.
        document.documentElement.lang = language;
        if (['ar'].includes(language)) {
            // Set the <html dir> attribute.
            setDirection('rtl');
            document.documentElement.dir = 'rtl';
        }
        dispatch({
            type: Types.UPDATE_LANGUAGE,
            payload: {
                language,
            }
        });
    }, []);

    const updateLoading = useCallback((loading: boolean) => {
        dispatch({
            type: Types.UPDATE_LOADING,
            payload: {
                loading
            }
        })
    }, []);


    const memoizedValue = useMemo(
        () => ({
            ...state,
            direction,
            // Add
            addIntegration,
            // Update
            updateColor,
            updateLogo,
            updateFavIcon,
            updatePageTitle,
            updateSurveyId,
            updatePublicKey,
            updateLanguage,
            updateLoading
        }),
        [
            state,
            direction,
            // Add
            addIntegration,
            // Update
            updateColor,
            updateLogo,
            updateFavIcon,
            updatePageTitle,
            updateSurveyId,
            updatePublicKey,
            updateLanguage,
            updateLoading
        ]
    );

    return <DataContext.Provider value={memoizedValue}>{children}</DataContext.Provider>;
}
