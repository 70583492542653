import {Navigate, Outlet, useRoutes} from 'react-router-dom';
import {lazy, Suspense} from "react";

const SurveyPage = lazy(() => import('../../pages/survey'));
const Page404 = lazy(() => import('../../pages/404'));
const ExpirationSession = lazy(() => import('../../pages/expiration-session'));
const ErrorPage = lazy(() => import('../../pages/error'));

export default function Router() {
    return useRoutes([
        {
            path: '/',
            element: (
                <Suspense>
                    <Outlet/>
                </Suspense>
            ),
            children: [
                {
                    path: '/:publicKey/:formId',
                    element: <SurveyPage/>,
                },
                {
                    path: '/form/:publicKey/:formId',
                    element: <SurveyPage/>,
                },
                {
                    path: '/survey/:publicKey/:surveyId',
                    element: <SurveyPage/>,
                },
                {
                    path: '/session/:surveyId',
                    element: <SurveyPage/>,
                },
                {
                    path: '/expiration-session',
                    element: <ExpirationSession/>,
                },
                {
                    path: '/error/:code',
                    element: <ErrorPage/>,
                },
                // No match 404
                {path: '*', element: <Navigate to="/error/404" replace/>}
            ]
        },
    ]);
}
